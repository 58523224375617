import React from 'react';
import { graphql } from 'gatsby';
// Todo: Re-add when implementing product images
// import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import { Col, Container, Row } from 'react-bootstrap';

import * as Globals from '../../../globals';
import Layout from '../../components/layout/layout';
import Seo from '../../components/seo/seo';
import ChatBanner from '../../components/chatBanner/chatBanner';
import Styles from './case-study.styles';

const SproutPage = ({ data }) => {
  let backgroundFluidImageStack = [
    data.background.childImageSharp.fluid,
    Globals.backgroundOverlay,
  ].reverse();

  return (
    <Layout>
      <Seo title="Much Smarter | Helping students love to learn" />
      <Styles>
        <BackgroundImage Tag="header" fluid={backgroundFluidImageStack}>
          <Container>
            <Row>
              <Col
                xs={{ span: 10, offset: 1 }}
                md={{ span: 8, offset: 0 }}
                xl={{ span: 6, offset: 0 }}
              >
                <h1>
                  <small>Much Smarter</small>
                  <br />
                  Helping students love to learn
                </h1>
              </Col>
            </Row>
          </Container>
        </BackgroundImage>

        <Container className="mt-4 mt-md-5 mb-4">
          <Row className="justify-content-center">
            <Col
              xs={{ span: 11, order: 2 }}
              md={{ span: 8, order: 1 }}
              lg={{ span: 7, offset: 1 }}
            >
              <section>
                <p className="lead mb-4 mb-md-5">
                  In an initial test of the Much Smarter product by 60 users,
                  ACT scores rose more than 30%, enabling the team to expand
                  their analog &amp; digital offerings to schools &amp;
                  students.
                </p>
                <h3>The Challenge</h3>
                <p>
                  Before partnering with Generativ, national tutoring practice
                  Much Smarter had built a sterling reputation for standardized
                  test score improvement; but after a decade in the business,
                  their growth hit a ceiling. Engaging Generativ, the company
                  sought to create a digital product that would deliver their
                  expertise to more students, allowing the company to scale
                  while introducing their practice to a broader audience,
                  including students whose families can't afford private
                  tutoring.
                </p>
                <h3>Our Approach</h3>
                <p>
                  Leveraging deep experience in product development and an
                  expert competitive research team, we designed and built a
                  product based on the most effective content and user
                  experience for Much Smarter's audience.
                </p>

                <p>
                  In the context of a long-term strategic plan, we developed a
                  course creation tool, tutor portal, gamification and analytics
                  for a digital product built on the Much Smarter approach.
                </p>
              </section>

              {/* Todo: Re-add after populating product images */}
              {/*<hr className="my-5 ml-0" />*/}
            </Col>

            <Col
              xs={{ span: 11, order: 1 }}
              md={{ span: 4, order: 2 }}
              lg={{ span: 3 }}
            >
              <aside className="mb-4 mb-md-5">
                <h4>Edtech</h4>

                <hr className="hr-small my-4 ml-0" />

                <ul>
                  <li>CTO</li>
                  <li>Product Design</li>
                  <li>Development</li>
                  <li>Advisory</li>
                </ul>

                <hr className="hr-small my-4 ml-0" />

                <a
                  href="https://www.muchsmarter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  muchsmarter.com
                </a>
              </aside>
            </Col>
          </Row>

          {/* Todo: Add product images */}
          {/*<Row className="justify-content-center">
            <Col xs={{ span: 11 }} md={{ span: 12 }} lg={{ span: 10 }}>
              <section className="product-images">
                <h2 className="mb-3">
                  Product Headline. Lorem ipsum dolor sit amet.
                </h2>
                <Row>
                  <Col>
                    <Img fluid={data.product.childImageSharp.fluid} />
                  </Col>
                  <Col>
                    <Img fluid={data.product.childImageSharp.fluid} />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Img fluid={data.product.childImageSharp.fluid} />
                  </Col>
                  <Col>
                    <Img fluid={data.product.childImageSharp.fluid} />
                  </Col>
                </Row>
              </section>
            </Col>
          </Row>*/}
        </Container>
      </Styles>
      <ChatBanner />
    </Layout>
  );
};

export const query = graphql`
  query {
    background: file(relativePath: { eq: "backgrounds/bg-work-ms.jpg" }) {
      ...imageFluidExtraLarge
    }
    product: file(relativePath: { eq: "placeholders/3x2.png" }) {
      ...imageFluidMedium
    }
  }
`;

export default SproutPage;
