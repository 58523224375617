import styled from '@emotion/styled';
import rhythm from '../../utils/rhythm';

const Styles = styled.main({
  h1: {
    small: {
      textTransform: 'capitalize',
      fontSize: `${rhythm(3 / 4)}`,
    },
  },

  h3: {
    display: 'block',
    marginTop: `${rhythm(3 / 2)}`,
  },

  aside: {
    position: 'sticky',
    top: `${rhythm(2)}`,
  },

  '.product-images': {
    '.col': {
      marginBottom: `${rhythm(3 / 2)}`,
    },
  },
});

export default Styles;
